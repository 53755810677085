import { LEOThemeConfig } from "LEOTheme/utils/type-utils";

export const leoThemeConfig: LEOThemeConfig = {
  disableProfilePictures: false,
  disableGQLFiles: [],
  disableThemeGQLFiles: [
    "DELETE_MODEL_PERMISSION",
    "ALL_USEROBJECTPERMISSION_SLIM",
    "ALL_USEROBJECTPERMISSION",
    "ASSIGN_MODEL_PERMISSION",
    "UPDATE_USER_IS_STAFF",
    "ROLE",
    "ROLES",
  ],
  appMetaData: {
    appTitle: "Competitor Information Portal",
    contactName: "Mikkel D. Lundorf",
    contactEmail: "MDYDK@leo-pharma.com",
    byline: "Developed by Data Solutions",
  },
};
